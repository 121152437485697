<template>
  <div class="w-8/12 pr-6">
    <div :style="backgroundImage" class="h-48 hlg:h-56 rounded-xl w-full p-5 flex items-end">
      <span class="font-bold text-white text-xl mb-3">Some title here</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WelcomeCard',
  computed: {
    backgroundImage() {
      return `background-image:linear-gradient(180deg, rgba(57, 119, 175, 0) 7.23%, #1F609C 86.48%), url('https://picsum.photos/200');`
    }
  }
}
</script>
