<template>
  <div>
    <div class="text-center mb-10 px-10 pt-12">
      <h2 class="mb-3">Welcome!</h2>
      <p class="text-darkViolet">Lorem ipsum dolor sit amet, consectetur adipiscing elit. A, metus,</p>
    </div>
    
    <flickity class="w-full" ref="flickity" :options="flickityOptions">
      <WelcomeCard v-for="card in 3" :key="card"/>
    </flickity>

    <div class="mx-10 mt-24">
      <router-link 
        :to="{name:'signUpName'}"
        class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
      >
        Get started
      </router-link>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import WelcomeCard from "@/components/register/welcome/WelcomeCard";

export default {
  name: "Welcome",
  components: {WelcomeCard, Flickity},
  mounted() {
    setTimeout(() => {
      this.$refs.flickity.resize();
    }, 100)
  },
  data() {
    return {
      flickityOptions: {
        imagesLoaded: true,
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: false,
      }
    }
  },
}
</script>

<style>
.flickity-page-dots {
  bottom: -25% !important;
}

.flickity-page-dots .dot {
  width: 14px !important;
  height: 14px !important;
  @apply bg-lightPurple !important;
}
</style>

